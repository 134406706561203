import React from 'react';
import { graphql } from 'gatsby';
import Layout from './Layout';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../components/sanity-client';

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }, mainImage: {}) {
      publishedAt(locale: "de", formatString: "DD.MMMM.YYYY")
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      title
      slug {
        current
      }
      author {
        name
      }
      _rawSubText
      _rawBody
      id
    }
  }
`;

const BlogPostTemplate = props => {
  const { data } = props;
  const builder = imageUrlBuilder(sanityClient);

  const urlFor = source => {
    return builder.image(source);
  };
  const post = data && data.post;

  const serial = {
    types: {
      imageBlog: props => {
        return (
          <span
            className={`image ${
              props.node.direction === 'Links'
                ? 'left'
                : props.node.direction === 'Voll'
                ? 'fit'
                : 'right'
            }`}
            style={{ zIndex: 1 }}
          >
            <img
              src={urlFor(props.node.image.asset).url()}
              alt={props.node.image.asset._ref}
            />
          </span>
        );
      },
    },
  };
  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{post.title}</h2>
            <h3 style={{ fontSize: 18 }}>
              <b>{`${post.author.name} - ${post.publishedAt}`}</b>
            </h3>

            <BlockContent style={{ marginTop: 20 }} blocks={post._rawSubText} />
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <BlockContent blocks={post._rawBody} serializers={serial} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogPostTemplate;
